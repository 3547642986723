import React, { useState, useEffect, Fragment } from 'react'
import { NavItem, Collapse, Nav } from "reactstrap"
import { NavLink } from "react-router-dom"
import PropTypes from 'prop-types'
import { convertToSlug } from './funcoes'

import AlunosIcon from '../Icons/Alunos';
import AvisoIcon from '../Icons/Aviso';
import AtividadesIcon from '../Icons/Atividades';
import CalendarioIcon from '../Icons/Calendario';
import DisciplinaIcon from '../Icons/Disciplinas';
import DashboardIcon from '../Icons/Dashboard';
import ProfessorIcon from '../Icons/Professor';
import PesquisarIcon from '../Icons/Pesquisar';
import ImportIcom from '../Icons/Import';
import LocaisIcon from '../Icons/Locais';
import { IoMdArrowDropdown } from 'react-icons/io'
import { FaSignOutAlt } from 'react-icons/fa'

function IconeMenu({ descricao }) {
	switch (convertToSlug(descricao)) {
		case "dashboards":
			return (
				<DashboardIcon width="1.7rem" height="1.7rem" />
			);
		case "eventos-e-noticias":
			return (
				<AvisoIcon width="1.7rem" height="1.7rem" />
			);
		case "disciplinas":
			return (
				<DisciplinaIcon width="1.7rem" height="1.7rem" />
			);
		case "professores":
			return (
				<ProfessorIcon width="1.7rem" height="1.7rem" />
			);
		case "locais-de-atividades":
			return (
				<LocaisIcon width="1.7rem" height="1.7rem" />
			);
		case "alunos":
			return (
				<AlunosIcon width="1.7rem" height="1.7rem" />
			);
		case "semana-padrao":
			return (
				<CalendarioIcon width="1.7rem" height="1.7rem" />
			);
		case "atividades-externas":
			return (
				<AtividadesIcon width="1.7rem" height="1.7rem" />
			);
		case "consultas":
			return (
				<PesquisarIcon width="1.7rem" height="1.7rem" />
			);
		case "importador":

			return (
				<ImportIcom width="1.7rem" height="1.7rem" />
			);
		default:
			return null
	}
}

function SidebarItem({ descricao, url, onClick, logout, sidebarAtivo, submenu, subfuncionalidades }) {
	const [submenuAtivo, setSubmenuAtivo] = useState(false);

	const ativarSubMenu = (event) => {
		event.preventDefault();
		setSubmenuAtivo(!submenuAtivo);
	};

	useEffect(() => {
		setSubmenuAtivo(false);
	}, [sidebarAtivo]);

	return logout ? (
		<NavItem >
			<NavLink exact to={url} onClick={logout}>
				<FaSignOutAlt size="1.7rem" />
				<span className={sidebarAtivo ? 'active' : ''}>{descricao}</span>
			</NavLink>
		</NavItem>
	) : (
		<NavItem>
			{submenu ? (
				<Fragment>
					<NavLink to={url} onClick={ativarSubMenu}>
						<IconeMenu descricao={descricao} />
						<span style={{ width: "calc(100% - 1.7rem - 1.5rem - 1.5rem)" }} className={sidebarAtivo ? 'active' : ''}>{descricao} <IoMdArrowDropdown size="1.3rem" className={submenuAtivo ? "active" : ""} /></span>
					</NavLink>
					<Collapse isOpen={submenuAtivo && sidebarAtivo} navbar>
						<Nav className="flex-column submenu">
							{subfuncionalidades.map((subfuncionalidade, indice) => (
								<NavLink exact to={url + subfuncionalidade.url} onClick={onClick} key={indice}>
									{subfuncionalidade.descricao}
								</NavLink>
							))}
						</Nav>
					</Collapse>
				</Fragment>
			) : (
				<NavLink exact to={url} onClick={onClick}>
					<IconeMenu descricao={descricao} />
					<span className={sidebarAtivo ? 'active' : ''}>{descricao}</span>
				</NavLink>
			)}
		</NavItem>
	);
}
SidebarItem.propTypes = {
	descricao: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired
}
export default SidebarItem;
